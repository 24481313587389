@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.stat {
  transition: --num 3s;
  counter-reset: num var(--num);
}

.stat::after {
  content: counter(num);
}
