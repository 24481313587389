* {
  box-sizing: border-box;
}

.app {
  display: flex;
  height: 100%;
  flex-direction: column;
}

h1 {
  font-size: 50px;
  margin: 0;
  padding: 0;
}

.header {
  margin-bottom: 10px;
}

.body {
  display: flex;
  height: 100%;
}

.wordcloudWrapper {
  background: linear-gradient(180deg, #2d4055 0%, #0f2439 100%);
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}

.wordcloudInner {
  background: linear-gradient(180deg, #1c2530 0%, #071626 100%);
  border-radius: 8px;
  height: 100%;
  width: 100%;
  flex: 1;
}

.side-widgets {
  display: flex;
  flex-direction: column;
  width: 280px;

  margin-left: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.widget {
  background: linear-gradient(180deg, #2d4055 0%, #0f2439 100%);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex: 1;

  img {
    position: absolute;
    z-index: 1;

    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .stat {
    z-index: 2;
    font-size: 80px;
    background: linear-gradient(#8ad8ee, #4f7b88);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    line-height: 85px;
    font-style: normal;
    margin-bottom: 10px;
  }

  span {
    font-family: Helvetica;
    color: #94b4d6;
  }
}

.energyBars {
  display: flex;
  flex-direction: rows;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.energyBarWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: end;

  span {
    font-size: 12px;
    font-weight: normal;
    flex-shrink: 0;
    flex: 0;
  }

  .bar {
    height: 100%;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: end;

    span {
      margin-bottom: 6px;
      font-size: 15px;
      color: #fff;
    }
  }

  .barFill {
    width: 100%;
    background: linear-gradient(#5076a0, #1b3e66);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: height 1s;
    transition-delay: 0.8s;
    flex-shrink: 0;
  }
}

#energyAfter .barFill {
  background: linear-gradient(#cbbe96, #4f4a3c);
}
